/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import Hero from 'components/elements/Hero'
import ProjectsSection from 'components/shared/ProjectsSection'
import KoelMonteurs from 'components/shared/before-footer/Koelmonteurs'
// import { ArrowLinkAnchor } from 'components/elements/ArrowLink'
import Image from 'components/elements/Image'
import Brochure from 'components/sidebar/Brochure'

// import downloadIcon from 'img/download.svg'

export const pageQuery = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      content
      templateDetailSpecs {
        bannerspecs {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality:100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
        }
        
        intro {
          description
        }
        
        proces {
          title
          download {
            localFile {
              publicURL
            }
          }
          downloadtext
          images {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1440, quality:100)
                }
                publicURL
              }
            }
          }
        }
        
        brochure {
          title
          contactbutton {
            title
            url
          }
          download {
            title
            file {
              localFile {
                publicURL
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { 
      seo,
      templateDetailSpecs
    },
  },
}) => {
  return (
    <Layout>
      {seo && (
        <SEO seo={seo} socialImage={templateDetailSpecs.bannerspecs.image.localFile.childImageSharp.meta.src} />
      )}

      <Hero src={templateDetailSpecs.bannerspecs.image} title={templateDetailSpecs.bannerspecs.title} />

      <section className="container pl-lg-5 py-5">
        <div className="row">
          <div className="col-lg-6 pr-5">
            <ParseContent content={templateDetailSpecs.intro.description} />
          </div>

          <Brochure brochure={templateDetailSpecs.brochure} />
        </div>
      </section>

      <section className="container position-relative pl-lg-5 mb-5">
        <div>
          <ParseContent content={templateDetailSpecs.proces.title} />
        </div>

        {/* <ArrowLinkAnchor className="mb-5" href={templateDetailSpecs.proces.download.localFile.publicURL} target="_blank" rel="noreferrer noopener">
          {templateDetailSpecs.proces.downloadText}
          <img className="ml-2" src={downloadIcon} alt="" />
        </ArrowLinkAnchor> */}

        {templateDetailSpecs.proces.images.map((image, index) => (
          image && image.image.localFile && image.image.localFile.publicURL ? (
            <a className="w-100" href={image.image.localFile.publicURL} download>
              <Plaatjie className="w-100" key={index} image={image.image} />
            </a>
          ) : (
            <a className="w-100" href={image.image} download>
              <Plaatjie className="w-100" key={index} image={image.image} />
            </a>
          )
        ))}
      </section>

      {/* <ProjectsSection /> */}
      <KoelMonteurs />
    </Layout>
  )
}

export default PageTemplate
